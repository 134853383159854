import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";

import { Button, Modal, Space } from "antd";

function CompleteBattel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  // const searchParams = new URLSearchParams(window.location.search);
  // const pageNumber = searchParams.get("page");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  console.log(searchParams);
  console.log(page);
  console.log(searchData);

  const battels_list_admin = useSelector((state) =>
    state?.allapi?.battels_list_admin ? state?.allapi?.battels_list_admin : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = battels_list_admin?.docs ? battels_list_admin?.docs : [];

  useEffect(() => {
    // if (search) {
    //   dispatch(allapiAction.getUserListseacrch({ pageNumber: page, key: search }));
    // } else {
      dispatch(allapiAction.getBettalList({pageNumber:page ,batteltype:"classicmanual", status: "complete"}));
    // }

    return () => {};
  }, [location]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    dispatch(
      allapiAction.addFoundByAdmin({
        id: userData?.id,
        amount: statusData?.amount,

        pageNumber: page,
      })
    );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.deleteByAdminBettal({
        _id: userId,
       
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };



  const hendleToChangeStatus = (id, status) => {
    dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Battles Id",
      dataIndex: "_id",
      key: "_id",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              onClick={() => navigate(`/battle-view/${item?._id}`)}
            >
              {item?._id}
            </span>
          </>
        );
      },
    },
    {
      title: "Battle Creator",
      dataIndex: "owner",
      key: "owner",
      className: "table_action_col",
      render: (value, item, index) => {


        console.log(item);
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              onClick={() => navigate(`/user-details/${item?.owner?.uid}`)}
            >
              {item?.owner?.uid}
            </span>
          </>
        );
      },
    },

    {
      title: "Entry Fee",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Winning Price",
      dataIndex: "prize",
      key: "prize",
    },
    {
      title: "Room Code",
      dataIndex: "roomcode",
      key: "roomcode",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
        title: "Battle Create",
        dataIndex: "battle Create",
        key: "battle Create",
        className: "table_action_col",
        render: (value, item, index) => {
          return (
            <>
              {/* <Tooltip title="Contact"> */}
              <span
                className="cursor-pointer"
                // onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                {moment(item?.createdAt).format(" DD/MM/YYYY hh:mm A")}
              </span>
            </>
          );
        },
      },
    
    // {
    //   title: "Student Mobile",
    //   dataIndex: "student_mobile",
    //   key: "student_mobile",
    // },
    // {
    //   title: "Parent Name",
    //   dataIndex: "parent_name",
    //   key: "parent_name",
    // },
    // {
    //   title: "Parent Mobile",
    //   dataIndex: "parent_mobile",
    //   key: "parent_mobile",
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                <SvgIcon.contacts className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModal(item?._id)}
              >
                Delete
              </button>
            </Tooltip>
            {/* <Tooltip title="Add Point">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewM(item)}
              >
                Add Point
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/users?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/battle?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add Amount"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Amount</Col>
            <Col span={24}>
              <Input
                name="amount"
                placeholder="enter amount"
                value={statusData?.amount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"COMPLETE BATTEL "}
            subHeading={`Total ${battels_list_admin?.totalDocs || 0} COMPLETE BATTEL `}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          // 
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: battels_list_admin?.totalDocs ? battels_list_admin?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default CompleteBattel;
