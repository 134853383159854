import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import ImageUploading from "react-images-uploading";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";
import { URL } from "../../Redux/common/url";

function WithdrawalRequest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  // const searchParams = new URLSearchParams(window.location.search);
  // const pageNumber = searchParams.get("page");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  console.log(searchParams);
  console.log(page);
  console.log(searchData);

  const withdrwarl_list = useSelector((state) =>
    state?.allapi?.withdrwarl_list ? state?.allapi?.withdrwarl_list : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = withdrwarl_list?.docs ? withdrwarl_list?.docs : [];

  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  useEffect(() => {
    if (search) {
      dispatch(allapiAction.getwithdrwarlAdminList({ pageNumber: page, key: search }));
    } else {
      dispatch(allapiAction.getwithdrwarlAdminList({ pageNumber: page }));
    }

    return () => { };
  }, [location]);

  function maskNumber(number) {
    if (number) {
      const numberString = number.toString(); // Convert the number to a string
      const maskedPortion = numberString.slice(0, -2).replace(/\d/g, "*"); // Replace all but the last 2 digits with asterisks
      const lastTwoDigits = numberString.slice(-2); // Get the last 2 digits
      return maskedPortion + lastTwoDigits;
    }
    // Combine the masked portion and last 2 digits
  }

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
    setstatusData(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    if (statusData?.status == "complete") {
      if (!statusData?.utr) {
        toast.error("Please enter utr");
        return
      }
      if (images?.length == 0) {
        toast.error("Please select image");
        return
      }

      const data = new FormData();
      data.append("_id", userData?._id);
      data.append("status", statusData?.status);
      data.append("reason", statusData?.reason);
      data.append("utr", statusData?.utr);

      // data.append('sellerId', selectedOption1?._id)
      if (images?.length > 0) {
        data.append("image", images[0]?.file);
      }
      dispatch(
        allapiAction.completeWithdrawalReq(data)
      );

      setstatusDataBox(false);
    } else {
      const data = new FormData();
      data.append("_id", userData?._id);
      data.append("status", statusData?.status);
     
      // data.append("utr", statusData?.utr);
      if (statusData?.status == "reject") {
        data.append("reason", statusData?.reason);
      }
      // data.append('sellerId', selectedOption1?._id)
      if (images?.length > 0) {
        data.append("image", images[0]?.file);
      }
      dispatch(
        allapiAction.completeWithdrawalReq(data)
      );

      setstatusDataBox(false);
    }


  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.user_inactive({
        _id: userId,
        status: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => { };
  };

  const hendleToChangeStatus = (id, status) => {
    dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
    // setuserId("");
    // setIsModalOpen(false);
    return () => { };
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    // {
    //   title: "Request ID",
    //   dataIndex: "_id",
    //   key: "_id",
    // },
    // {
    //   title: "User Id",
    //   dataIndex: "uid",
    //   key: "uid",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>

    //         <span
    //           className="cursor-pointer coustomNevc"
    //           onClick={() => navigate(`/user-details/${item?.userId?.uid}`)}
    //         >
    //           {item?.userId?._id}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Mobile",
      dataIndex: "number",
      key: "number",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
            //   onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {maskNumber(item?.userId?.number)}
            </span>
          </>
        );
      },
    },
    {
      title: "User uid",
      dataIndex: "uid",
      key: "uid",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              onClick={() => navigate(`/user-details/${item?.userId?.uid}`)}
            >
              {item?.userId?.uid}
            </span>
          </>
        );
      },
    },

    {
      title: "Amount",
      dataIndex: "current_balance",
      key: "current_balance",
    },

    {
      title: "payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
    },
    {
      title: "Account Holder",
      dataIndex: "accountHolder",
      key: "accountHolder",
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
    },
    {
      title: "ifsc",
      dataIndex: "ifsc",
      key: "ifsc",
    },
    {
      title: "upi",
      dataIndex: "upi",
      key: "upi",
    },

    {
      title: "Request Payment",
      dataIndex: "requestPayment",
      key: "requestPayment",
    },
    {
      title: "fee",
      dataIndex: "fee",
      key: "fee",
    },
    {
      title: "Payable Amount",
      dataIndex: "payableAmount",
      key: "payableAmount",
    },
    {
      title: "Utr",
      dataIndex: "utr",
      key: "utr",
    },
    {
      title: "Image",
      dataIndex: "Image",
      key: "Image",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <div style={{width:"50px"}}>
              <img src={URL?.API_BASE_URL + item?.image} alt="" style={{width:"100%"}} />
            </div>
          </>
        );
      },
    },
    {
      title: "Request Date",
      dataIndex: "Request Date",
      key: "Request Date",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
            // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title={item?.status}>
              <button
                className={
                  item?.status == "complete"
                    ? "success-button ml-3"
                    : item?.status == "pending" ? "filter-button ml-3" : "delete-button ml-3"
                }
                onClick={() => {
                  if (item?.status == "pending") {
                    hendleToViewM(item)
                  }
                }}
              >
                {item?.status}
              </button>
            </Tooltip>
          </>
        );
      },
    },
    // {
    //   title: "Student Mobile",
    //   dataIndex: "student_mobile",
    //   key: "student_mobile",
    // },
    // {
    //   title: "Parent Name",
    //   dataIndex: "parent_name",
    //   key: "parent_name",
    // },
    // {
    //   title: "Parent Mobile",
    //   dataIndex: "parent_mobile",
    //   key: "parent_mobile",
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>

    //         <Tooltip title="Add Point">
    //           <button
    //             className="filter-button ml-3  mt-5"
    //             onClick={() => hendleToViewM(item)}
    //           >
    //             Add Point
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/withdrawal-request?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);



    if (search) {
      //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
      //   return () => {};
      navigate("/withdrawal-request?page=" + e + "&search=" + search);
    } else {
      //   dispatch(allapiAction.getuserList(e));
      navigate("/withdrawal-request?page=" + e);
      //   return () => {};
    }
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Withdrawal status"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>

            <Col span={24}>approve</Col>
            <Col span={24}>
              {" "}
              <select
                name="status"
                className="form-select"
                value={statusData?.status}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              >
                <option value={""}>select status</option>
                <option value='pending'>Pending</option>
                <option value='reject'>Rejected</option>
                {/* <option value='complete'>In Process</option> */}
                <option value='complete'>Complete</option>
              </select>
            </Col>
            {statusData?.status == 'reject' && <Col span={24}>Reason</Col>}
            {statusData?.status == 'reject' && <Col span={24}>
              <Input
                name="reason"
                placeholder="enter reason"
                value={statusData?.reason}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>}


            {statusData?.status == 'complete' && <Col span={24}>Utr</Col>}
            {statusData?.status == 'complete' && <Col span={24}>
              <Input
                name="utr"
                placeholder="enter utr"
                value={statusData?.utr}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>}

            {
              statusData?.status == 'complete' && <Col span={24}> image</Col>}
            {statusData?.status == 'complete' && <Col span={24}>
              <ImageUploading
                // multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper">
                    <button
                      className="btn btn-primary btn-sm rounded"
                      style={isDragging ? { color: "red" } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Click or Drop here
                    </button>
                    &nbsp;

                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image["data_url"]} alt="" width="100" />
                        <div className="image-item__btn-wrapper">
                          <button
                            className="btn btn-primary btn-sm rounded mb-2"
                            onClick={() => onImageUpdate(index)}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-primary btn-sm rounded"
                            onClick={() => onImageRemove(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
            </Col>}
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"CURRENT REQUEST"}
            subHeading={`Total ${withdrwarl_list?.totalDocs || 0
              } CURRENT REQUEST`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            style={{backgroundColor: '#1E201E', color: 'white'}}
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
          // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          // 
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: withdrwarl_list?.totalDocs ? withdrwarl_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default WithdrawalRequest;
