import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import {
  UserOutlined,
  WalletOutlined,
  DeleteOutlined,
  TrophyOutlined,
} from "@ant-design/icons";

import { Button, Modal, Space } from "antd";

function User() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const heading = [
    ["closeBal", "winningBal"],
    "uid",
    "number",
    "referCode",
    "status",
    "createdAt",
  ];

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  // console.log(searchParams);
  // console.log(page);
  // console.log(searchData);

  const users_list = useSelector((state) =>
    state?.allapi?.users_list ? state?.allapi?.users_list : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = users_list?.docs ? users_list?.docs : [];

  useEffect(() => {
    if (search) {
      dispatch(
        allapiAction.getUserListseacrch({
          pageNumber: page,
          key: search,
          heading,
        })
      );
    } else {
      dispatch(allapiAction.getuserList({ page, heading }));
    }

    return () => {};
  }, [location]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenstatus, setIsModalOpenstatus] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBox1, setstatusDataBox1] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
  };
  const hendleToViewMp = (e) => {
    setstatusDataBox1(true);
    setuserData(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    dispatch(
      allapiAction.addFoundByAdmin({
        id: userData?._id,
        amount: statusData?.amount,

        pageNumber: page,
      })
    );

    setstatusDataBox(false);
  };
  const hendleToSubmetUpdate1 = () => {
    dispatch(
      allapiAction.addpelantyByAdmin({
        id: userData?._id,
        amount: statusData?.amount,
        description: statusData?.description,

        pageNumber: page,
      })
    );

    setstatusDataBox1(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.user_inactive({
        _id: userId,
        status: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const [userIddetails, setuserIddetails] = useState({});
  const handleOkstatus = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );

    dispatch(
      allapiAction.user_inactive({
        _id: userIddetails?._id,
        status: userIddetails?.status,
        // active: 0,
        pageNumber: page,
      })
    );
    // setuserId("");
    setIsModalOpenstatus(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    setuserIddetails({ _id: id, status: status });
    setIsModalOpenstatus(true);
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelStatus = () => {
    setIsModalOpenstatus(false);
  };

  function maskNumber(number) {
    if (number) {
      const numberString = number.toString(); // Convert the number to a string
      const maskedPortion = numberString.slice(0, -2).replace(/\d/g, "*"); // Replace all but the last 2 digits with asterisks
      const lastTwoDigits = numberString.slice(-2); // Get the last 2 digits
      return maskedPortion + lastTwoDigits;
    }
    // Combine the masked portion and last 2 digits
  }

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "User Id",
      dataIndex: "uid",
      key: "uid",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}

            <span
              className="cursor-pointer coustomNevc"
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => navigate(`/user-details/${item?.uid}`)}
            >
              <UserOutlined /> | {item?.uid}
            </span>
          </>
        );
      },
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.uid}`)}
            >
              {maskNumber(item?.number)}
            </span>
          </>
        );
      },
    },

    // {
    //   title: "Number",
    //   dataIndex: "number",
    //   key: "number",
    // },
    {
      title: "Wallet",
      dataIndex: "Wallet",
      key: "Wallet",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              style={{ display: "flex", alignItems: "center" }}
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              <WalletOutlined />: &nbsp; &#x20B9;{item?.walletId?.closeBal} |{" "}
              <TrophyOutlined />: &nbsp; &#x20B9;{item?.walletId?.winningBal}
            </span>
          </>
        );
      },
    },
    {
      title: "Reffer Code",
      dataIndex: "referCode",
      key: "referCode",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title={item?.status}>
              <button
                style={{
                  backgroundColor: `${
                    item?.status == "Active" ? "green" : "red"
                  }`,
                  border: "none",
                  color: "white",
                  height: "30px",
                  fontSize: "12px",
                  borderRadius: "5px",
                  padding: "5px 10px 5px 10px",
                }}
                onClick={() =>
                  hendleToChangeStatus(
                    item?._id,
                    item?.status == "Active" ? "Inactive" : "Active"
                  )
                }
              >
                {item?.status}
              </button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Registered On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY HH:mm A")}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Parent Name",
    //   dataIndex: "parent_name",
    //   key: "parent_name",
    // },
    // {
    //   title: "Parent Mobile",
    //   dataIndex: "parent_mobile",
    //   key: "parent_mobile",
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                <SvgIcon.contacts className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            {/* <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModal(item?.id)}
              >
                Delete
              </button>
            </Tooltip> */}
            <div
              style={{
                display: "flex",
                gap: "5px",
                height: "30px",
                fontSize: "12px",
                color: "#ffffff",
              }}
            >
              <Tooltip title="Penalty">
                <button
                  style={{
                    padding: "2px",
                    flexShrink: "0",
                    backgroundColor: "red",
                    border: "1px solid red",
                  }}
                  onClick={() => hendleToViewMp(item)}
                >
                  &#x20B9; Penalty
                </button>
              </Tooltip>

              <Tooltip title="Add Point">
                <button
                  style={{
                    padding: "2px",
                    flexShrink: "0",
                    backgroundColor: "green",
                    border: "1px solid green",
                  }}
                  onClick={() => hendleToViewM(item)}
                >
                  &#x20B9; Add Point
                </button>
              </Tooltip>
              {/* <i class="fa-solid fa-user"></i> */}
            </div>
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/users?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/users?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>
      <Modal
        title="Status"
        open={isModalOpenstatus}
        onOk={handleOkstatus}
        onCancel={handleCancelStatus}
      >
        <p>Are you sure for change user status</p>
      </Modal>

      <Modal
        title="Add Amount"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Amount</Col>
            <Col span={24}>
              <Input
                name="amount"
                placeholder="enter amount"
                value={statusData?.amount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        title="Penalty"
        centered
        open={statusDataBox1}
        onOk={() => hendleToSubmetUpdate1(false)}
        onCancel={() => setstatusDataBox1(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Amount</Col>
            <Col span={24}>
              <Input
                name="amount"
                placeholder="enter amount"
                value={statusData?.amount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            <Col span={24}>Description</Col>
            <Col span={24}>
              <Input
                name="description"
                placeholder="enter description"
                value={statusData?.description}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Users"}
            subHeading={`Total ${users_list?.totalDocs || 0} Users`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            style={{ backgroundColor: "#1E201E", color: "white" }}
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={" "}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.totalDocs ? users_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default User;
