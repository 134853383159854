import React, { useEffect, useState } from "react";
import {
  DashboardOutlined,
  UsergroupAddOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
  SettingOutlined,
  UserOutlined,
  FileOutlined,
  ApartmentOutlined,
  FileSearchOutlined,
  MoneyCollectOutlined,
  PayCircleOutlined,
  UserSwitchOutlined,
  TransactionOutlined,
  MessageOutlined,
  PlusCircleOutlined,
  SmileOutlined,
  LikeOutlined,
  FlagOutlined,
  RiseOutlined,
  RedoOutlined
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import SidebarFooter from "./SidebarFooter";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { useBasePath } from "../../hooks/useBasePath";
import SvgIcon from "../../const/SvgIcon";
import { ShowMenu } from "../../utils";
const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  const navigate = useNavigate();
  const { openKey, selectKey } = useBasePath();

  return (
    <Sider
      className="layout_sidebar"
      collapsible
      collapsed={collapsed}
      width={240}
      style={{ background: "#1E201E" }}
      trigger={!collapsed ? <SidebarFooter /> : null}
    >
      {/* <Logo collapsed={collapsed} /> */}
      <div className="sidebar_menus">
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={selectKey}
          defaultOpenKeys={openKey}
          style={{ background: "#1E201E" }}
          items={[
            {
              key: "dashboard",
              icon: <DashboardOutlined />,
              label: "DASHBOARD",
              onClick: () => {
                navigate("/");
              },
            },
            {
              key: "users",
              icon: <UserOutlined />,
              label: "USERS",
              style: { display: ShowMenu(["USERS"]) },

              children: [
                {
                  key: "USERS",
                  label: "ALL USERS",
                  icon: <UserOutlined />,
                  style: { display: ShowMenu(["ALL USERS"]) },
                  onClick: () => {
                    navigate("/users");
                  },

                },


                {
                  key: "USERS-RECENT",
                  label: "RECENT JOIN USERS",
                  icon: <UserOutlined />,
                  style: { display: ShowMenu(["RECENT JOIN USERS"]) },
                  onClick: () => {
                    navigate("/recent-user");
                  },
                },]
            },

            {
              key: "PAYMENT",
              icon: <PayCircleOutlined />,
              label: "PAYMENT",
              style: { display: ShowMenu(["PAYMENT"]) },


              children: [
                {
                  key: "RECENT PAYMENT",
                  label: "RECENT PAYMENT",
                  icon: <MoneyCollectOutlined />,
                  style: { display: ShowMenu(["RECENT PAYMENT"]) },
                  onClick: () => {
                    navigate("/payment-recent");
                  },

                },

              ]


            },

            //MATCH CONFLICTS
            {

              key: "MATCHCONFLICTS",
              icon: <FileSearchOutlined />,
              label: "MATCH CONFLICTS",
              style: { display: ShowMenu(["MATCH CONFLICTS", "RUNNING BATTEL", "CONFLICT CHALLENGES", "COMPLETE BATTEL"]) },

              children: [
                {
                  key: "RUNNINGBATTEL",
                  label: "RUNNING BATTEL",
                  icon: <UserOutlined />,
                  style: { display: ShowMenu(["RUNNING BATTEL"]) },
                  onClick: () => {
                    navigate("/battle");
                  },

                },


                {
                  key: "CONFLICT-CHALLENGES",
                  label: "CONFLICT CHALLENGES",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["CONFLICT CHALLENGES"]) },
                  onClick: () => {
                    navigate("/winner-request");
                  },
                },


                {
                  key: "COMPLETE-BATTEL",
                  label: "COMPLETE BATTEL",
                  icon: <FlagOutlined />,
                  style: { display: ShowMenu(["COMPLETE BATTEL"]) },
                  onClick: () => {
                    navigate("/complete-battel");
                  },
                },

               
                // /open-battel


                {
                  key: "open-battel",
                  label: "OPEN BATTEL",
                  icon: <RedoOutlined />,
                  style: { display: ShowMenu(["Open Battel"]) },
                  onClick: () => {
                    navigate("/open-battel");
                  },
                },


                {
                  key: "battle-popular",
                  label: "BATTE POPULAR",
                  icon: <RiseOutlined />,
                  style: { display: ShowMenu(["Battle Popular"]) },
                  onClick: () => {
                    navigate("/battle-popular");
                  },
                },


              ]

            },

            // ⦁	KYC REQUEST
            {
              key: "kyc-pending",
              icon: <QuestionCircleOutlined />,
              label: "KYC REQUEST",
              style: { display: ShowMenu(["KYC REQUEST"]) },
              onClick: () => {
                navigate("/kyc-request");
              },
            },

            // WITHDRAWAL REQUEST 
            {
              key: "withdrawal-request",
              icon: <QuestionCircleOutlined />,
              label: "WITHDRAWAL REQUEST ",
              style: { display: ShowMenu(["WITHDRAWAL REQUEST"]) },

              children: [
                {
                  key: "CURRENT-REQUEST",
                  label: "CURRENT REQUEST",
                  icon: <UserOutlined />,
                  style: { display: ShowMenu(["CURRENT REQUEST"]) },
                  onClick: () => {
                    navigate("/withdrawal-request");
                  },

                },

                {
                  key: "RECENT-APROOVE-REQUEST",
                  label: "RECENT APROOVE REQUEST ",
                  icon: <LikeOutlined />,
                  style: { display: ShowMenu(["RECENT APROOVE REQUEST"]) },
                  onClick: () => {
                    navigate("/RecenteApprove");
                  },

                },
              ]
            },

            // settings

            {
              key: "setting",
              icon: <SettingOutlined />,
              label: "SETTINGS",
              style: { display: ShowMenu(["SETTINGS"]) },
              onClick: () => {
                navigate("/setting");
              },
            },

            // manual account list

            {
              key: "account-list",
              icon: <UserSwitchOutlined />,
              label: "MANUAL ACCOUNT LIST",
              style: { display: ShowMenu(["Account List"]) },
              onClick: () => {
                navigate("/account-list");
              },
            },

            // message
            {
              key: "message",
              icon: <MessageOutlined />,
              label: "MESSAGE",
              style: { display: ShowMenu([" Message"]) },
              onClick: () => {
                navigate("/message");
              },


            },



            {
              key: "transaction",
              icon: <TransactionOutlined />,
              label: "TRANSACTION",
              style: { display: ShowMenu(["Transaction"]) },
              onClick: () => {
                navigate("/transaction");
              },
            },


            {
              key: "point-request",
              icon: <PlusCircleOutlined />,
              label: "POINT REQUEST",
              style: { display: ShowMenu(["Point Request"]) },
              onClick: () => {
                navigate("/point-request");
              },
            },

            // TOP 10 PLAYERS LIST 

            {
              key: "top-10",
              icon: <SmileOutlined />,
              label: "TOP 10 PLAYERS LIST",
              style: { display: ShowMenu(["TOP 10 PLAYERS LIST"]) },
              onClick: () => {
                navigate("/top-10");
              },

            },


          ]}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
